<template>
  <div class="columns">
    <div class="column">
      <p>
        <a
          :href="link"
          target="_blank"
        >
          {{ link }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Link',

  props: {
    link: {
      type: String,
    },
  },
};
</script>
