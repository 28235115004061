import axios from 'axios';

const client = axios.create({
  baseURL: `${process.env.VUE_APP_API}/api`,
  withCredentials: true,
  headers: {
    post: {
      'Content-Type': 'application/json',
    },
  },
});

export default client;
