import client from '@/services/client';

const loadAll = (context) => {
  client.get('/texts').then(
    (response) => context.commit('SET_TEXTS', response.data),
  ).catch(
    (error) => console.error(error),
  );
};

export default {
  loadAll,
};
