export default {
  methods: {
    parsed(text) {
      if (typeof text !== 'string') return text;

      const re = /((?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$]))/igm;

      return text.replace(re, "<a href='$1' target='_blank'>$1</a>");
    },
  },
};
