const SET_PROM = (state, data) => {
  state.prom = data.data;
};

const SET_PROMS = (state, data) => {
  state.proms = data.data;
};

export default {
  SET_PROM,
  SET_PROMS,
};
