<template>
  <div id="app">
    <nav-bar />

    <div id="contentWrapper">
      <router-view />
    </div>

    <htx-footer />
  </div>
</template>

<style lang="scss">
  @import "assets/scss/style.scss";
</style>

<script>
import NavBar from './components/NavBar.vue';
import HtxFooter from './components/HtxFooter.vue';

export default {
  name: 'App',

  components: {
    NavBar,
    HtxFooter,
  },

  metaInfo() {
    return {
      title: this.$t('meta.title'),
      meta: [
        {
          name: 'description',
          content: this.$t('meta.description'),
        },
        {
          property: 'language',
          content: 'en',
        },
        {
          property: 'og:title',
          content: this.$t('meta.title'),
        },
        {
          property: 'og:url',
          content: 'https://prom-select.eu',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: 'https://prom-select.eu/storage/images/htx_logo_web.png',
        },
        {
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:description',
          content: this.$t('meta.description'),
        },
        {
          name: 'robots',
          content: 'index,follow',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Rijksoverheid";
  src: local("Rijksoverheid"),
  url(/storage/fonts/Rijksoverheid/ROsanswebtextregular.ttf) format("truetype");
}

#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

#contentWrapper {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
</style>
