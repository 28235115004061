<template>
  <div class="columns">
    <div class="column">
      <h1 class="title">{{ name }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Name',

  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
