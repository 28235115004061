import client from '@/services/client';

const load = (context, id) => {
  client.get(`/proms/${id}`).then(
    (response) => context.commit('SET_PROM', response.data),
  ).catch(
    (error) => console.error(error),
  );
};

const loadAll = (context) => {
  client.get('/proms').then(
    (response) => context.commit('SET_PROMS', response.data),
  ).catch(
    (error) => console.error(error),
  );
};

export default {
  load,
  loadAll,
};
