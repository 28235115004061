<template>
  <footer class="footer">
    <div class="container">
      <div class="content has-text-centered">
        <div class="columns is-vcentered">
          <div class="column is-one-fifth">
            <p>
              <img
                id="euFlag"
                src="/storage/images/Flag_of_Europe.svg.png"
                alt="EU flag: image not found"
                title="EU Flag"
              />
            </p>
          </div>

          <div class="column">
            <p>
              This project has received funding from the European Union’s Horizon 2020 research and
              innovation programme under grant agreement No 825162.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'HtxFooter',
};
</script>

<style lang="scss">
footer {
  margin-top: auto;
}

.footer {
  background-color: transparent !important;
}

#euFlag {
  max-width: 123px;
}
</style>
