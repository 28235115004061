import Vue from 'vue';

import VueI18n from 'vue-i18n';
import VueMeta from 'vue-meta';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import locales from './lang/locales';

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(VueMeta);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: locales,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
