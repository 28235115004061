<template>
  <div class="container">
    <div
      v-if="isLoading"
      class="notification"
    >
      {{ $t('loading') }}
    </div>

    <template v-else>
      <div class="columns">
        <div
          v-for="(list, idx) in ['domains', 'caseStudies']"
          :key="idx"
          class="column is-half"
        >
          <select-param
            :list="list"
            :caption="$t(`select_${list}`)"
            :items="getItems(list)"
            :selected="selected[list]"
            :available="available(list)"
            @selected="onSelect"
          ></select-param>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div class="control">
            <input
              class="input"
              type="text"
              :placeholder="$t('search.placeholder')"
              v-model="filter"
            >
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <select-result
            :result="filteredProms.length"
            :filter="filter"
            :selected="selected"
            @clear="reset"
          />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <div
            v-for="(prom, idx) in filteredProms"
            :key="idx"
            class="box"
          >
            <router-link :to="{ name: 'prom', params: { id: prom.id } }">
              {{ prom.name | trimmed }}
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectParam from '../components/SelectParam.vue';
import SelectResult from '../components/SelectResult.vue';
import text from '../mixins/text';

export default {
  name: 'Proms',

  mixins: [
    text,
  ],

  components: {
    SelectParam,
    SelectResult,
  },

  filters: {
    trimmed(val) {
      if (typeof val !== 'string') return val;

      return (val.length > 150) ? `${val.substr(0, 150)}...` : val;
    },
  },

  data() {
    return {
      filter: '',

      selected: {
        domains: [],
        caseStudies: [],
      },
    };
  },

  computed: {
    ...mapGetters({
      proms: 'proms/proms',
      domains: 'domains/domains',
      caseStudies: 'caseStudies/caseStudies',
    }),

    isLoading() {
      const checks = [
        this.proms,
        this.selected,
        this.caseStudies,
      ];

      return checks.includes(null);
    },

    filteredProms() {
      if (!this.filter || this.filter.length === 0) return this.selectedProms;

      const needle = this.filter.toLowerCase();

      return this.selectedProms.filter((prom) => {
        const haystack = Object.values(prom.other);

        haystack.push(prom.name);

        return haystack.join('\n').toLowerCase().includes(needle);
      });
    },

    selectedProms() {
      if (!this.isLoading) {
        if (this.noSelection()) return this.proms;

        const domainIds = this.pluck(this.query('domains'), 'id');
        const caseStudyIds = this.pluck(this.query('caseStudies'), 'id');

        const intersect = this.intersect(domainIds, caseStudyIds);

        return this.proms.filter((prom) => intersect.includes(prom.id));
      }

      return [];
    },
  },

  created() {
    this.$store.dispatch('proms/loadAll');
    this.$store.dispatch('domains/loadAll');
    this.$store.dispatch('caseStudies/loadAll');
  },

  mounted() {
    if (this.$attrs.query) {
      const split = this.$attrs.query.split(':');
      const re = new RegExp('([0-9]+)', 'g');

      ['domains', 'caseStudies'].forEach((item, idx) => {
        const ids = split[idx].matchAll(re);

        [...ids].forEach((id) => {
          this.selected[item].push(parseInt(id[0], 10));
        });
      });
    }
  },

  methods: {
    getItems(list) {
      return this[list];
    },

    query(list) {
      let hits = this.proms;

      if (!this.noSelection(list)) {
        hits = this.proms.filter(
          (prom) => this.intersect(prom[list], this.selected[list]).length > 0,
        );
      }

      return hits;
    },

    intersect(needles, haystack) {
      return needles.filter((id) => haystack.includes(id));
    },

    pluck(items, key) {
      return items.map((item) => item[key]);
    },

    available(list) {
      const otherList = (list === 'domains') ? 'caseStudies' : 'domains';

      return new Set(this.pluck(this.query(otherList), list).flat());
    },

    noSelection(list = null) {
      if (list) return this.selected[list].length === 0;

      return !Object.values(this.selected).map((items) => items.length === 0).includes(false);
    },

    onSelect({ id, list }) {
      if (this.selected[list].includes(id)) {
        this.selected[list] = this.selected[list].filter((item) => item !== id);
      } else {
        this.selected[list].push(id);
      }

      const params = `d(${this.selected.domains.join(',')}):cs(${this.selected.caseStudies.join(',')})`;

      this.$router.push({ name: 'proms', params: { query: params } });
    },

    reset() {
      this.filter = '';

      this.selected = {
        domains: [],
        caseStudies: [],
      };

      this.$router.push({ name: 'proms' });
    },
  },
};
</script>

<style lang="scss">
.card {
  margin-bottom: 15px;
}
</style>
