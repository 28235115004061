<template>
  <div class="container">
    <div class="content htx">
      <h1 class="content" v-html="title"></h1>
      <p class="content" v-html="par1"></p>
      <p class="content" v-html="par2"></p>
      <p class="content" v-html="par3"></p>
      <p class="content" v-html="par4"></p>
      <p class="content" v-html="par5"></p>
      <p>
        <img
          src="/storage/images/prom-cycle.png"
          alt="PROM-cycle: image not found"
          title="PROM-cycle"
        >
      </p>
      <p class="content" v-html="par6"></p>
      <p class="content" v-html="par7"></p>
    </div>
  </div>
</template>

<script>
import content from '../mixins/content';

export default {
  name: 'Home',

  mixins: [
    content,
  ],
};
</script>

<style lang="scss">
a {
  text-decoration: underline;
}

.htx {
  strong {
    color: #1f4e79;
  }

  .prom {
    color: #00b1f1;

    &-dark {
      color: #000066;
    }

    &-light {
      color: #1f4e79;
    }

    &-red {
      color: #ff0000;
    }

    &-lime {
      color: #00cd9a;
    }

    &-green {
      color: #339966;
    }

    &-salmon {
      color: #f5b184;
    }
  }
}
</style>
