<template>
  <div class="table-container">
    <table class="table">
      <tr v-show="source !== null">
        <th>{{ $t('source') }}</th>
        <td class="content" v-html="parsed(source)"></td>
      </tr>
      <tr
        v-for="(key, idx) in keys"
        v-show="!empty(key)"
        :key="idx"
      >
        <th>{{ key }}</th>

        <td class="content" v-html="parsed(fields[key])"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import text from '../mixins/text';

export default {
  name: 'Other',

  props: {
    fields: {
      type: Object,
      required: true,
    },

    source: {
      type: String,
      required: true,
    },
  },

  mixins: [
    text,
  ],

  computed: {
    keys() {
      return Object.keys(this.fields);
    },
  },

  methods: {
    empty(key) {
      return this.fields[key] === null;
    },

    isLink(val) {
      if (val === null) return false;

      return (typeof val === 'string') ? val.includes('https') : false;
    },
  },
};
</script>
