import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Prom from '../views/Prom.vue';
import Proms from '../views/Proms.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/proms/:query?',
    name: 'proms',
    component: Proms,
    props: true,
  },
  {
    path: '/proms/view/:id',
    name: 'prom',
    component: Prom,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
