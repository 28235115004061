import Vue from 'vue';
import Vuex from 'vuex';

import proms from './modules/proms';
import texts from './modules/texts';
import domains from './modules/domains';
import caseStudies from './modules/caseStudies';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    proms,
    texts,
    domains,
    caseStudies,
  },
});
