<template>
  <div id="selectResult">
    <hr>

    <div class="columns is-mobile">
      <div class="column">
        <h4 class="title is-6">{{ $t('result.result') }}</h4>
      </div>
      <div class="column">
        <button
          v-show="!noSelection"
          class="button is-warning is-pulled-right"
          @click="$emit('clear')"
        >
          {{ $t('result.clear') }}
        </button>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <p v-if="noSelection">{{ $t('result.noSelection') }}</p>

        <p v-else>
          <strong>{{ $t('result.selectedDomains') }}</strong> <em>{{ selectedDomains }}</em>
          <br>
          <strong>{{ $t('result.selectedCaseStudies') }}</strong> <em>{{ selectedCaseStudies }}</em>
          <br>

          => {{ $tc('result.found', result, { count: result }) }}
        </p>
      </div>
    </div>

    <hr>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SelectResult',

  props: {
    filter: {
      type: String,
      required: true,
    },

    selected: {
      type: Object,
      required: true,
    },

    result: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters({
      domains: 'domains/domains',
      caseStudies: 'caseStudies/caseStudies',
    }),

    noSelection() {
      return this.selected.domains.length === 0
        && this.selected.caseStudies.length === 0
        && this.filter.length === 0;
    },

    selectedDomains() {
      if (this.selected.domains.length === 0) return this.$t('result.none');

      return this.selected.domains.map((domainId) => this.domains.find((domain) => domain.id === domainId).name).join(', ');
    },

    selectedCaseStudies() {
      if (this.selected.caseStudies.length === 0) return this.$t('result.none');

      return this.selected.caseStudies.map((caseStudyId) => this.caseStudies.find((caseStudy) => caseStudy.id === caseStudyId).name).join(', ');
    },
  },
};
</script>

<style scoped>

</style>
